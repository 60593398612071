/* eslint-disable react/no-unescaped-entities */
import { TextDivider } from '@discretize/react-discretize-components'
import { MDXProvider } from '@mdx-js/react'
import { List, ListItem, ListItemText, Typography } from '@mui/material'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Attribute } from '../../components/gw2components'
import Layout from '../../components/Layout'
import Message from '../../components/Message'
import GuideHeader from '../../components/navigation/GuideHeader'
import Link from '../../components/navigation/Link'
import SeoHeader from '../../components/SeoHeader'
import SimpleList from '../../components/SimpleList'
import components from '../../utils/remarkable'

const layout = {
  SeoProps: {
    title: 'Contribution Guide',
    description: 'Find out how to contribute to the Discretize website',
  },
  ContainerProps: {
    paper: true,
  },
}

function ContributePage({ location }) {
  const mdx = useStaticQuery(graphql`
    query MyQuery {
      allMdx(filter: { fields: { section: { eq: "extra" } } }) {
        edges {
          node {
            id
            fields {
              section
            }
            body
            internal {
              contentFilePath
            }
          }
        }
      }
    }
  `)
  const docs = mdx.allMdx.edges.find((md) =>
    md.node.internal.contentFilePath.includes('editorDefaultText'),
  )

  return (
    <Layout ContainerProps={layout.ContainerProps} location={location}>
      <SeoHeader path={location.pathname} {...layout.SeoProps} />

      <GuideHeader
        title="Contribution Guide"
        date="2021-10-03"
        timeToRead="5"
      />

      <Typography variant="body2" paragraph>
        Contributing to the Discretize website has never been easier before! All
        you need to get started is a Github account, a little bit of brain
        power, and the ability to read.
      </Typography>

      <Typography variant="h5">Get started:</Typography>
      <SimpleList ordered>
        <ListItem>
          <ListItemText>
            Create a free Github account at {'  '}
            <Link enableIcon to="https://github.com/signup">
              github.com
            </Link>
            . The sign-up is free, does not require any personal information
            except for an e-mail address and should only take 2 minutes.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Sign into our CMS (=content management system) at{' '}
            <Link enableIcon to="https://staging.discretize.eu/cms/">
              www.discretize.eu/cms
            </Link>{' '}
            and accept the creation of a fork.
          </ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>
            Navigate to any site you want to edit. It is recommended to disable
            "Sync scrolling" on the very right side of the screen for an optimal
            experience. Make your changes, and click "Publish" to save your
            changes. Please be aware that clicking "Publish" does not actually
            publish your changes but saves them. After a review your changes
            will appear first on{' '}
            <Link enableIcon to="staging.discretize.eu">
              staging.discretize.eu{' '}
            </Link>
            , and later on the main website.
          </ListItemText>
        </ListItem>
      </SimpleList>

      <Message>
        The CMS requires access to your full account because it acts on your
        behalf (oauth). This is a static page, there are no server components
        and no outgoing requests are made that could possibly steal your oauth
        token. Your login never leaves your PC.{' '}
        <Link to="https://github.com/netlify/netlify-cms/issues/4329">
          More information
        </Link>
        .<br />
        As an alternative you can copy-paste the guide files into the{' '}
        <Link to="/editor">editor</Link>.
      </Message>

      <Typography variant="h5">Important information:</Typography>
      <SimpleList>
        <ListItem>
          <ListItemText>
            Adding new sites from the CMS is not possible. Please ask on the
            discord server for help. An admin will create the page for you and
            it will appear in the cms.
          </ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>
            In case there are elements, that are not rendering as intended on
            the staging site, please add an enter before and after the
            component. That should fix all rendering issues given the component
            is correctly set up.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Pictures are not rendering in the cms!</ListItemText>
        </ListItem>
      </SimpleList>

      <Typography variant="h5">Documentation CMS:</Typography>
      <List dense component="ul">
        <ListItem>
          Equipment
          <SimpleList dense component="ul">
            <ListItem>
              <ListItemText>
                To create the gear display it is recommended to study an
                existing example first. Copy-paste it, and replace the gear
                sections as follows.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                Go to the{' '}
                <Link enableIcon to="https://optimizer.discretize.eu/">
                  Gear Optimizer{' '}
                </Link>
                . Insert the build you want to get the equipment for (including
                the correct <Attribute name="Agony Resistance" />
                ), hit calculate and click the button at the bottom of the
                screen to copy the equipment.
              </ListItemText>
            </ListItem>
          </SimpleList>
        </ListItem>
      </List>

      <TextDivider text="Markdown Components" />

      {docs?.node && (
        <MDXProvider components={components}>{docs.node.children}</MDXProvider>
      )}
    </Layout>
  )
}

export default ContributePage
